import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const HtmlSitemap = () => {
  return (
    <Layout>
      <SEO
        title="Leadership Development Program - Business Coaching Services"
        description="Schedule your complimentary coaching session with AGL & learn how our leadership development program & business coaching services will help foster a growth mindset in employees."
      />
      <div className="page-headline">
        <div className="container">
          <div className="section-heading text-center">
            <h1>
              <strong> HTML Sitemap</strong>
            </h1>
          </div>
        </div>
        <div className="headline-bg" />
      </div>
      <div className="page-content html-sitemap">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4">
              <ul>
                <h2>Home</h2>
                <li>
                  <a
                    href="https://coachingforgood.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Leadership Development Coaching"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Leadership
                    Development Coaching
                  </a>
                </li>
                <h2>Blog</h2>
                <li>
                  <a
                    href="https://coachingforgood.org/blog"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Developing Leadership Skills"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Developing
                    Leadership Skills
                  </a>

                  <a
                    href="https://coachingforgood.org/how-to-develop-a-growth-mindset"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="How To Develop A Growth Mindset"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>How To Develop A
                    Growth Mindset{" "}
                  </a>

                  <a
                    href="https://coachingforgood.org/the-most-wonderful-time-of-the-year"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Wonderful Time of The Year"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Wonderful Time of
                    The Year
                  </a>
                  <a
                    href="https://coachingforgood.org/how-to-create-a-conducive-home-office"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Conducive Home Office "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Conducive Home
                    Office
                  </a>
                  <a
                    href="https://coachingforgood.org/critical-tips-for-working-from-home"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Working From Home Tips "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Working From Home
                    Tips
                  </a>
                  <a
                    href="https://coachingforgood.org/how-to-lead-well-in-times-of-crisis-and-uncertainty"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="How To Lead During Crisis "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>How To Lead During
                    Crisis
                  </a>
                  <a
                    href="https://coachingforgood.org/why-every-great-leader-needs-a-great-coach"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Why Every Team Needs A Great Coach "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Why Every Team
                    Needs A Great Coach
                  </a>
                  <a
                    href="https://coachingforgood.org/can-positive-emotions-impact-racism"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Positive Emotions Impavting Racism "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Positive Emotions
                    Impavting Racism
                  </a>
                  <a
                    href="https://coachingforgood.org/8-fun-and-creative-ways-to-show-your-team-some-love-during-covid-19"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="8 Creative Ways To Show Team Love "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>8 Creative Ways To
                    Show Team Love
                  </a>
                  <a
                    href="https://coachingforgood.org/how-to-have-the-most-engaging-zoom-team-meetings"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Engaging Zoom Meetings "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Engaging Zoom
                    Meetings
                  </a>
                  <a
                    href="https://coachingforgood.org/challenge"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Challenge "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Challenge
                  </a>
                  <a
                    href="https://coachingforgood.org/mornings-and-success"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Mornigns & Success"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Mornigns & Success
                  </a>
                  <a
                    href="https://coachingforgood.org/don-t-make-it-hard"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Don't Make It Hard"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Don't Make It Hard
                  </a>
                  <a
                    href="https://coachingforgood.org/the-illusion-of-having-to-be-fast"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Illusion Of Having To Be Fast "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Illusion Of Having
                    To Be Fast
                  </a>
                  <a
                    href="https://coachingforgood.org/meditation-and-stories"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Meditation "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Meditation
                  </a>
                  <a
                    href="https://coachingforgood.org/love-does-the-thing"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Love Does Things "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Love Does Things
                  </a>
                  <a
                    href="https://coachingforgood.org/rest-in-love"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Rest In Love "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Rest In Love
                  </a>
                  <a
                    href="https://coachingforgood.org/just-love"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Just Love "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Just Love
                  </a>
                  <a
                    href="https://coachingforgood.org/what-s-next"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Whats Next "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Whats Next
                  </a>
                  <a
                    href="https://coachingforgood.org/how-well-is-your-soul"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="How Is Your Soul"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>How Is Your Soul
                  </a>
                  <a
                    href="https://coachingforgood.org/all-about-mindsets"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="All About Mindset"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>All About Mindset
                  </a>
                  <a
                    href="https://coachingforgood.org/freedom"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Freedom "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Freedom
                  </a>
                  <a
                    href="https://coachingforgood.org/uprooting-or-grounding"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Uprooting Or Grounding "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Uprooting Or
                    Grounding
                  </a>
                  <a
                    href="https://coachingforgood.org/routine-routine-routine"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Routine "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Routine
                  </a>
                  <a
                    href="https://coachingforgood.org/do-what-works"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Do What Works "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Do What Works
                  </a>
                  <a
                    href="https://coachingforgood.org/managing-the-jitters"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Managing Jitters"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Managing Jitters
                  </a>
                  <a
                    href="https://coachingforgood.org/ideas-versus-plans"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Ideas vs Plans "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Ideas vs Plans
                  </a>
                  <a
                    href="https://coachingforgood.org/productivity"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Productivity "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Productivity
                  </a>
                  <a
                    href="https://coachingforgood.org/no-such-thing-as-normal"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="No Such Thing As Normal "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>No Such Thing As
                    Normal
                  </a>
                  <a
                    href="https://coachingforgood.org/surrender"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Surrender"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Surrender
                  </a>
                  <a
                    href="https://coachingforgood.org/don-t-wait-to-retire"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Don't Wait To Retire "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Don't Wait To
                    Retire
                  </a>
                  <a
                    href="https://coachingforgood.org/mountains-and-perspective"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Mountains & Perspective "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Mountains &
                    Perspective
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 mb-4">
              <ul>
                <h2>About Us</h2>
                <li>
                  <a
                    href="https://coachingforgood.org/about-us"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Leadership Development Coaching"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Leadership
                    Development Coaching
                  </a>
                </li>
                <h2>Contact</h2>
                <li>
                  <a
                    href="https://coachingforgood.org/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Executive Coaching & Consulting"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Executive Coaching
                    & Consulting
                  </a>
                </li>
                <h2>Newsletter</h2>
                <li>
                  <a
                    href="https://coachingforgood.org/360review"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="360 Degree Review & Assessment "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>360 Degree Review &
                    Assessment
                  </a>
                </li>
                <h2>Virtual All Staff</h2>
                <li>
                  <a
                    href="https://coachingforgood.org/virtual-all-staff"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Leadership Development Training "
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Leadership
                    Development Training
                  </a>
                </li>
                <h2>360 Review</h2>
                <li>
                  <a
                    href="https://coachingforgood.org/newsletter"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Corporate Coaching Companies"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Corporate Coaching
                    Companies
                  </a>
                </li>
                <h2>Resource Sitemap</h2>
                <li>
                  <a
                    href="https://coachingforgood.org/resource-sitemap"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Corporate Coaching Companies"
                  >
                    <i className="fa fa-link fa-xs mr-2"></i>Resource Sitemap
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default HtmlSitemap
